import React, { useState, useEffect } from 'react';
import { useUser } from '../contexts/UserContext';
import Header from '../components/Header';
import { Calendar, ChevronDown, ChevronUp } from 'lucide-react';
import { fetchFromAirtable } from '../utils/api';
import config from '../utils/config';

const TIME_PERIODS = [
  { value: 'day', label: 'Today' },
  { value: 'week', label: 'This Week' },
  { value: 'month', label: 'This Month' },
  { value: 'all', label: 'All Time' },
];

export default function MealHistoryPage() {
  const { userEmail } = useUser();
  const [meals, setMeals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [timePeriod, setTimePeriod] = useState('month');
  const [groupedMeals, setGroupedMeals] = useState({});
  const [collapsedDates, setCollapsedDates] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMeals = async () => {
      try {
        setIsLoading(true);
        if (!userEmail) throw new Error('User email is missing.');

        const mealsResponse = await fetchFromAirtable(config.tables.meals, {
          filterByFormula: `LOWER({userEmail}) = LOWER('${userEmail}')`,
          sort: [{ field: 'Date', direction: 'desc' }],
        });

        if (mealsResponse.records) {
          const processedMeals = mealsResponse.records.map((record) => ({
            id: record.id,
            date: record.fields.Date,
            shortDescription: record.fields.foodName|| 'Unnamed Meal',
            calories: parseFloat(record.fields.calories|| 0),
            protein: parseFloat(record.fields.protein|| 0),
            carbs: parseFloat(record.fields.carbs|| 0),
            fat: parseFloat(record.fields.fat|| 0),
            photo: record.fields.photo?.[0]?.url|| null,
            timeString: new Date(record.fields.Date).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            }),
          }));

          const filteredMeals = filterMealsByTimePeriod(processedMeals);
          groupMealsByDate(filteredMeals);
          setMeals(filteredMeals);
        } else {
          setMeals([]);
        }
      } catch (err) {
        console.error('Error fetching meals:', err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMeals();
  }, [userEmail, timePeriod]);

  const filterMealsByTimePeriod = (meals) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    return meals.filter((meal) => {
      const mealDate = new Date(meal.date);
      switch (timePeriod) {
        case 'day':
          return mealDate >= today;
        case 'week':
          const weekAgo = new Date(now);
          weekAgo.setDate(now.getDate() - 7);
          return mealDate >= weekAgo;
        case 'month':
          const monthAgo = new Date(now);
          monthAgo.setMonth(now.getMonth() - 1);
          return mealDate >= monthAgo;
        default:
          return true;
      }
    });
  };

  const groupMealsByDate = (meals) => {
    const grouped = meals.reduce((acc, meal) => {
      const date = new Date(meal.date).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(meal);
      return acc;
    }, {});
    setGroupedMeals(grouped);
  };

  const toggleDateCollapse = (date) => {
    setCollapsedDates((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header />
        <div className="flex justify-center items-center h-[calc(100vh-64px)]">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header />
        <div className="flex justify-center items-center h-[calc(100vh-64px)] text-red-600">
          Error: {error}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Meal History</h1>
          <select
            value={timePeriod}
            onChange={(e) => setTimePeriod(e.target.value)}
            className="px-4 py-2 border rounded-md bg-white"
          >
            {TIME_PERIODS.map((period) => (
              <option key={period.value} value={period.value}>
                {period.label}
              </option>
            ))}
          </select>
        </div>

        {Object.entries(groupedMeals)
          .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
          .map(([date, dayMeals]) => (
            <div key={date} className="bg-white rounded-lg shadow mb-4">
              <button
                onClick={() => toggleDateCollapse(date)}
                className="w-full px-6 py-4 flex justify-between items-center hover:bg-gray-50"
              >
                <div className="flex items-center gap-2">
                  <Calendar className="w-5 h-5 text-gray-500" />
                  <h2 className="text-lg font-semibold">
                    {new Date(date).toLocaleDateString('en-US', {
                      weekday: 'long',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </h2>
                </div>
                <div className="flex items-center gap-4">
                  <span className="text-sm text-gray-500">
                    {dayMeals.length} meals •{' '}
                    {Math.round(
                      dayMeals.reduce((sum, meal) => sum + meal.calories, 0)
                    )}{' '}
                    calories
                  </span>
                  {collapsedDates[date] ? (
                    <ChevronDown className="w-5 h-5" />
                  ) : (
                    <ChevronUp className="w-5 h-5" />
                  )}
                </div>
              </button>
              {!collapsedDates[date] && (
                <div className="divide-y">
                  {dayMeals.map((meal) => (
                    <div key={meal.id} className="p-4 hover:bg-gray-50">
                      <div className="flex gap-4">
                        <div className="w-24 h-24 bg-gray-100 rounded-lg overflow-hidden">
                          {meal.photo && (
                            <img
                              src={meal.photo}
                              alt={meal.shortDescription}
                              className="w-full h-full object-cover"
                            />
                          )}
                        </div>
                        <div className="flex-1">
                          <div className="flex justify-between">
                            <div>
                              <h3 className="text-lg font-semibold">
                                {meal.shortDescription}
                              </h3>
                              <div className="text-sm text-gray-500">
                                {meal.timeString}
                              </div>
                            </div>
                            <div className="text-lg font-semibold">
                              {Math.round(meal.calories)} cal
                            </div>
                          </div>
                          <div className="grid grid-cols-3 gap-4 mt-2">
                            <div className="text-sm">
                              <span className="text-gray-500">Protein:</span>{' '}
                              {Math.round(meal.protein)}g
                            </div>
                            <div className="text-sm">
                              <span className="text-gray-500">Carbs:</span>{' '}
                              {Math.round(meal.carbs)}g
                            </div>
                            <div className="text-sm">
                              <span className="text-gray-500">Fat:</span>{' '}
                              {Math.round(meal.fat)}g
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}
