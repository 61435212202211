import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

const Header = () => {
  const navigate = useNavigate();
  const { userData, logout } = useUser();
  const [time, setTime] = useState(new Date());
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const handleLogout = async () => {
    await logout();
    navigate('/signed-out'); // 👈 Create this route!
  };

  return (
    <header className="bg-white shadow-sm w-full">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-3 flex items-center justify-between">
        <Link to="/" className="text-xl font-bold text-blue-600" style={{ direction: 'rtl' }}>
          מצלמזון
        </Link>

        {/* Toggle button for mobile */}
        <button
          className="sm:hidden text-gray-700"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          ☰
        </button>

        {/* Desktop nav */}
        <nav className="hidden sm:flex gap-4 items-center text-sm font-medium text-gray-600">
          <Link to="/" className="hover:text-blue-600">Dashboard</Link>
          <Link to="/TakePhotoPage" className="hover:text-blue-600">Take Photo</Link>
          <Link to="/meals" className="hover:text-blue-600">Meal History</Link>
          <Link to="/ProfilePage" className="hover:text-blue-600">Profile</Link>
          <Link to="/insights" className="hover:text-blue-600">Insights</Link>
          <Link to="/calendar" className="hover:text-blue-600">Calendar</Link>
        </nav>

        {/* Right side user info */}
        <div className="hidden sm:flex items-center gap-4 text-sm text-gray-600">
          <span>{time.toLocaleTimeString()}</span>
          <span>{userData?.name || userData?.email || 'User'}</span>
          <button
            onClick={handleLogout}
            className="px-3 py-1 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Logout
          </button>
        </div>
      </div>

      {/* Mobile menu */}
      {menuOpen && (
        <nav className="sm:hidden flex flex-col px-4 pb-4 gap-2 text-sm text-gray-700">
          <Link to="/" onClick={() => setMenuOpen(false)}>Dashboard</Link>
          <Link to="/TakePhotoPage" onClick={() => setMenuOpen(false)}>Take Photo</Link>
          <Link to="/meals" onClick={() => setMenuOpen(false)}>Meal History</Link>
          <Link to="/ProfilePage" onClick={() => setMenuOpen(false)}>Profile</Link>
          <Link to="/insights" onClick={() => setMenuOpen(false)}>Insights</Link>
          <Link to="/calendar" onClick={() => setMenuOpen(false)}>Calendar</Link>
          <div className="flex justify-between mt-2 items-center">
            <span>{userData?.name || userData?.email || 'User'}</span>
            <button
              onClick={handleLogout}
              className="text-blue-600 font-medium"
            >
              Logout
            </button>
          </div>
        </nav>
      )}
    </header>
  );
};

export default Header;
