import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

export default function AuthPage() {
  const navigate = useNavigate();
  const { login, register, loginWithGoogle, loading, error } = useUser();
  const [isLogin, setIsLogin] = useState(true);
  const [isNavigating, setIsNavigating] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    confirmPassword: '',
  });
  const [formError, setFormError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateForm = () => {
    if (!formData.email || !formData.password) {
      setFormError('Please fill in all required fields');
      return false;
    }

    if (!isLogin) {
      if (!formData.name) {
        setFormError('Please enter your name');
        return false;
      }
      if (formData.password !== formData.confirmPassword) {
        setFormError('Passwords do not match');
        return false;
      }
    }

    setFormError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm() || isNavigating) return;

    try {
      setIsNavigating(true);
      if (isLogin) {
        await login(formData.email, formData.password);
      } else {
        await register({
          email: formData.email,
          password: formData.password,
          name: formData.name,
          role: 'user',
        });
      }
      localStorage.setItem('userData', JSON.stringify({ email: formData.email }));
      window.dispatchEvent(new Event('storage'));
      navigate('/');
    } catch (err) {
      setFormError(err.message);
    } finally {
      setIsNavigating(false);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const user = await loginWithGoogle();
      localStorage.setItem('userData', JSON.stringify({ email: user.email }));
      window.dispatchEvent(new Event('storage'));
      navigate('/');
    } catch (err) {
      console.error('Google login failed:', err);
      setFormError(err.message);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
      <div className="w-full max-w-sm bg-white shadow-md rounded-xl p-6">
        <div className="flex flex-col items-center">
          <img
            src="https://i.ibb.co/7Yvd0SJ/2268453-1-l-removebg-preview.png"
            alt="Calorify"
            className="w-20 h-20 mb-2 object-contain"
          />
          <h1 className="text-2xl font-bold text-blue-600 mb-2">Calorify</h1>
          <h2 className="text-lg text-gray-700 mb-4 text-center">
            {isLogin ? 'Sign in to your account' : 'Create a new account'}
          </h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          {!isLogin && (
            <div>
              <label htmlFor="name" className="text-sm text-gray-700">
                Name
              </label>
              <input
                id="name"
                name="name"
                type="text"
                required={!isLogin}
                value={formData.name}
                onChange={handleInputChange}
                className="w-full mt-1 px-3 py-2 border rounded-md text-sm"
              />
            </div>
          )}

          <div>
            <label htmlFor="email" className="text-sm text-gray-700">
              Email address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              required
              value={formData.email}
              onChange={handleInputChange}
              className="w-full mt-1 px-3 py-2 border rounded-md text-sm"
            />
          </div>

          <div>
            <label htmlFor="password" className="text-sm text-gray-700">
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              required
              value={formData.password}
              onChange={handleInputChange}
              className="w-full mt-1 px-3 py-2 border rounded-md text-sm"
            />
          </div>

          {!isLogin && (
            <div>
              <label htmlFor="confirmPassword" className="text-sm text-gray-700">
                Confirm Password
              </label>
              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                required={!isLogin}
                value={formData.confirmPassword}
                onChange={handleInputChange}
                className="w-full mt-1 px-3 py-2 border rounded-md text-sm"
              />
            </div>
          )}

          {(formError || error) && (
            <p className="text-red-500 text-sm">{formError || error}</p>
          )}

          <button
            type="submit"
            disabled={loading}
            className={`w-full py-2 text-white bg-blue-600 rounded-md text-sm font-semibold hover:bg-blue-700 transition ${
              loading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {loading ? 'Processing...' : isLogin ? 'Sign in' : 'Register'}
          </button>

          <button
            type="button"
            onClick={handleGoogleLogin}
            className="w-full mt-2 flex justify-center items-center gap-2 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-100"
          >
            <img
              src="https://www.svgrepo.com/show/475656/google-color.svg"
              alt="Google"
              className="h-4 w-4"
            />
            Sign in with Google
          </button>
        </form>

        <div className="mt-4">
          <button
            onClick={() => setIsLogin(!isLogin)}
            className="w-full text-blue-600 hover:underline text-sm text-center"
          >
            {isLogin ? 'Need an account? Register' : 'Already have an account? Sign in'}
          </button>
        </div>
      </div>
    </div>
  );
}
