import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  onAuthStateChanged,
  getRedirectResult
} from 'firebase/auth';
import { auth } from '../config/firebase';
import { isMobile } from 'react-device-detect';
import Airtable from 'airtable';

// Use environment variables from .env file
const AIRTABLE_API_KEY = import.meta.env.VITE_AIRTABLE_API_KEY;
const AIRTABLE_BASE_ID = import.meta.env.VITE_AIRTABLE_BASE_ID;
const AIRTABLE_USERS_TABLE = import.meta.env.VITE_AIRTABLE_USERS_TABLE;
console.log('Airtable API Key:', AIRTABLE_API_KEY);  // Debugging log to check the API key
console.log('Airtable Base ID:', AIRTABLE_BASE_ID);  // Debugging log to check the Base ID
console.log('Airtable Users Table:', AIRTABLE_USERS_TABLE);  

const UserContext = createContext(null);

const MAKE_ENDPOINTS = {
  login: 'https://hook.eu2.make.com/iivrtysk01ubbmvbct7y0w4hsbyylqql',
  register: 'https://hook.eu2.make.com/daxeafhu8419vb9r85oih5x66va14eqm',
  getProfile: 'https://hook.eu2.make.com/n5cderg3nrlb7caeing8dmb8g6ns5voc'
};

export function UserProvider({ children }) {
  const [userEmail, setUserEmail] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [firebaseReady, setFirebaseReady] = useState(false);

  useEffect(() => {
    const handleUser = async (user) => {
      if (user) {
        // Trim spaces from email
        const email = user.email.toLowerCase().trim(); // Ensure email is clean and matched correctly
        console.log('[handleUser] User logged in:', email);

        // Airtable API setup
        const base = new Airtable({ apiKey: AIRTABLE_API_KEY }).base(AIRTABLE_BASE_ID);
        const usersTable = base(AIRTABLE_USERS_TABLE); // Your Airtable table name

        // Check if the user exists in Airtable using their email
        const records = await usersTable
          .select({
            filterByFormula: `{Email} = "${email}"`, // Ensure correct Airtable query
            maxRecords: 1,
          })
          .firstPage();

        console.log('[Airtable Query] Records:', records); // Debugging log to check Airtable query results

        if (records.length > 0) {
          // If the user exists, update their last login date
          const userRecord = records[0];
          console.log('[handleUser] User already exists in Airtable:', email);

          await usersTable.update(userRecord.id, {
            'Last Login': new Date().toISOString(),
          });
        } else {
          // If the user doesn't exist, create a new user record in Airtable
          console.log('[handleUser] User does not exist in Airtable. Creating new record:', email);

          const userObject = {
            'Email': email,
            'Name': user.displayName || '',
            'Role': 'user',
            'Source': 'google',
            'Last Login': new Date().toISOString(),
          };

          await usersTable.create([userObject]);
        }

        // Store user data in localStorage
        const userObject = {
          email,
          name: user.displayName || '',
          google: true,
        };
        localStorage.setItem('userData', JSON.stringify(userObject));
        setUserData(userObject);
        setUserEmail(email);

        // Trigger webhook only once per session
        const alreadySynced = sessionStorage.getItem('webhookSynced');
        if (!alreadySynced) {
          try {
            await fetch(MAKE_ENDPOINTS.login, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ email }),
            });
            sessionStorage.setItem('webhookSynced', 'true');
          } catch (error) {
            console.error('Webhook failed:', error);
          }
        }
      } else {
        console.log('[handleUser] No user found. Logging out.');
        localStorage.removeItem('userData');
        setUserData(null);
        setUserEmail(null);
      }
    };

    // First handle redirect (mobile)
    getRedirectResult(auth)
      .then((result) => {
        if (result?.user) {
          handleUser(result.user);
        }
      })
      .catch((error) => {
        console.error('Redirect login error:', error);
      });

    // Then listen for auth state change
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      console.log('[firebaseReady] Auth state changed:', firebaseUser); // Debug log for auth state changes
      await handleUser(firebaseUser);
      setFirebaseReady(true);  // Set firebaseReady to true only after auth state is completely processed
      console.log('[firebaseReady] Firebase state is now ready.');
    });

    return () => unsubscribe();
  }, []);

  const handleAuthResponse = async (data) => {
    if (!data.success) throw new Error(data.message || 'Authentication failed');

    const email = data.email.toLowerCase();

    const userObject = {
      email,
      name: data.name || '',
      password: data.password,
    };

    localStorage.setItem('userData', JSON.stringify(userObject));
    setUserData(userObject);
    setUserEmail(email);

    return data;
  };

  const login = async (email, password) => {
    try {
      setLoading(true);
      setError(null);

      const response = await fetch(MAKE_ENDPOINTS.login, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const text = await response.text();
      const data = JSON.parse(text);

      return await handleAuthResponse(data);
    } catch (error) {
      setError(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const register = async (userData) => {
    try {
      setLoading(true);
      setError(null);

      const response = await fetch(MAKE_ENDPOINTS.register, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData),
      });

      const text = await response.text();
      const data = JSON.parse(text);

      if (!data.email || !data.password || !data.name) {
        throw new Error('Registration response missing required fields');
      }

      return await handleAuthResponse({
        success: true,
        email: data.email,
        password: data.password,
        name: data.name,
        role: data.role || 'user'
      });
    } catch (error) {
      setError(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      await auth.signOut();
      localStorage.removeItem('userData');
      sessionStorage.removeItem('webhookSynced');
      setUserData(null);
      setUserEmail(null);
    } catch (err) {
      console.error("Logout error:", err);
    }
  };

  const loginWithGoogle = async () => {
    try {
      setLoading(true);
      const provider = new GoogleAuthProvider();

      if (isMobile) {
        await signInWithRedirect(auth, provider);
        return null;
      }

      const result = await signInWithPopup(auth, provider);
      return result;
    } catch (error) {
      setError(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <UserContext.Provider value={{
      userEmail,
      userData,
      login,
      register,
      logout,
      loginWithGoogle,
      loading,
      error,
      firebaseReady
    }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);
  if (context === null) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}
