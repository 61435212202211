import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import DashboardPage from './pages/DashboardPage';
import AuthPage from './pages/AuthPage';
import TakePhotoPage from './pages/TakePhotoPage';
import MealHistoryPage from './pages/MealHistoryPage';
import CalendarView from './pages/CalendarView';
import { UserProvider } from './contexts/UserContext';
import ProfilePage from './pages/ProfilePage';
import InsightsPage from './pages/InsightsPage';
import PrivateRoute from './components/PrivateRoute'; // ✅ make sure this file exists

const App = () => {
  return (
    <UserProvider>
      <Router>
        <Routes>
          {/* Public Route */}
          <Route path="/auth" element={<AuthPage />} />

          {/* Protected Routes */}
          <Route path="/" element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          } />

          <Route path="/TakePhotoPage" element={
            <PrivateRoute>
              <TakePhotoPage />
            </PrivateRoute>
          } />

          <Route path="/meals" element={
            <PrivateRoute>
              <MealHistoryPage />
            </PrivateRoute>
          } />

          <Route path="/calendar" element={
            <PrivateRoute>
              <CalendarView />
            </PrivateRoute>
          } />

          <Route path="/ProfilePage" element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          } />

          <Route path="/insights" element={
            <PrivateRoute>
              <InsightsPage />
            </PrivateRoute>
          } />

          {/* Fallback to auth */}
          <Route path="*" element={<Navigate to="/auth" replace />} />
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default App;
