// src/config/firebase.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  browserLocalPersistence,
  setPersistence
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// Your Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyBWOm2x8Jt31wX1bZpXrzKNX51283sFCNo",
  authDomain: "healthme-aaa99.firebaseapp.com",
  projectId: "healthme-aaa99",
  storageBucket: "healthme-aaa99.appspot.com",
  messagingSenderId: "1087059529003",
  appId: "1:1087059529003:web:6b34c87e2af9ca74b3c96e",
  measurementId: "G-GWGVLYPWLY"
};

// ✅ Initialize Firebase app
const app = initializeApp(firebaseConfig);

// ✅ Auth + persistence
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence).catch((error) => {
  console.error("Failed to set persistence:", error);
});

const db = getFirestore(app);
const analytics = getAnalytics(app);

export { auth, db };
