import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

export default function PrivateRoute({ children }) {
  const { userEmail, firebaseReady } = useUser();

  if (!firebaseReady) {
    // ✅ Better loading state (can be customized)
    return (
      <div className="flex flex-col items-center justify-center min-h-screen text-blue-600">
        <img
          src="https://i.ibb.co/7Yvd0SJ/2268453-1-l-removebg-preview.png"
          alt="Loading..."
          className="w-16 h-16 animate-spin mb-4"
        />
        <p className="text-lg font-medium">Loading your dashboard...</p>
      </div>
    );
  }

  // ✅ If logged in, render child components. Else, redirect to /auth.
  return userEmail ? children : <Navigate to="/auth" replace />;
}
