import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { useUser } from '../contexts/UserContext';
import config from '../utils/config';
import DailyIntakeCard from '../components/DailyIntakeCard';
import { fetchFromAirtable, updateAirtableRecord } from '../utils/api';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

const NUTRIENT_COLORS = {
  calories: { bg: 'bg-blue-50', text: 'text-blue-600', progress: 'bg-blue-600' },
  protein: { bg: 'bg-green-50', text: 'text-green-600', progress: 'bg-green-600' },
  carbs: { bg: 'bg-yellow-50', text: 'text-yellow-600', progress: 'bg-yellow-600' },
  fat: { bg: 'bg-red-50', text: 'text-red-600', progress: 'bg-red-600' },
};

const MEAL_WINDOWS = {
  breakfast: { start: 6, end: 10, name: 'Breakfast' },
  lunch: { start: 11, end: 14, name: 'Lunch' },
  dinner: { start: 17, end: 21, name: 'Dinner' },
  snack: { start: 0, end: 23, name: 'Snack' },
};

export default function DashboardPage() {
  const navigate = useNavigate();
  const { userEmail } = useUser();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [dailyStats, setDailyStats] = useState(null);
  const [dailyMeals, setDailyMeals] = useState([]);
  const [isEditingTargets, setIsEditingTargets] = useState(false);
  const [editedTargets, setEditedTargets] = useState({
    dailyCalorieTarget: 0,
    dailyProteinTarget: 0,
    dailyCarbsTarget: 0,
    dailyFatTarget: 0,
  });
  
  const [weeklyData, setWeeklyData] = useState({
    '7 days': [],
    calories: [],
    protein: [],
    carbs: [],
    fat: [],
  });
  
  const [notifications, setNotifications] = useState([]);

  // Function to add notifications
  const addNotification = (message, type = 'info') => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, message, type }]);
    setTimeout(() => {
      setNotifications(prev => prev.filter(n => n.id !== id));
    }, 5000);
  };

  // Helper to calculate progress percentage
  const getProgressPercentage = (current, target) => {
    if (!target) return 0;
    return Math.min(Math.round((current / target) * 100), 100);
  };

  // Helper to get local date in 'YYYY-MM-DD' format with timezone
  const getLocalDate = () => {
    const options = { timeZone: 'Asia/Jerusalem', year: 'numeric', month: '2-digit', day: '2-digit' };
    const today = new Date();
    const localDate = today.toLocaleDateString('en-CA', options); // 'en-CA' for 'YYYY-MM-DD'
    return localDate;
  };

  // Function to fetch daily meals
  const fetchDailyMeals = async () => {
    try {
      console.log('Fetching daily meals...');
      const localDate = getLocalDate();
      console.log('Fetching meals for local date:', localDate);
      
      const mealsResponse = await fetchFromAirtable(config.tables.meals, {
        filterByFormula: `AND(LOWER({userEmail}) = LOWER('${userEmail}'), DATETIME_FORMAT(SET_TIMEZONE({Date}, 'Asia/Jerusalem'), 'YYYY-MM-DD') = '${localDate}')`,
        sort: [{ field: 'Date', direction: 'desc' }],
      });

      console.log('Meals Response:', mealsResponse);

      if (mealsResponse.records && mealsResponse.records.length > 0) {
        const meals = mealsResponse.records.map((record) => {
          console.log('Record Fields:', record.fields); // Debugging
          return {
            id: record.id,
            foodName: record.fields.foodName || 'Unnamed Meal',
            calories: parseFloat(record.fields.calories) || 0, // Corrected field name
            protein: parseFloat(record.fields.protein) || 0,   // Corrected field name
            carbs: parseFloat(record.fields.carbs) || 0,       // Corrected field name
            fat: parseFloat(record.fields.fat) || 0,           // Corrected field name
            timestamp: record.fields.Date,
            photoUrl: record.fields.photo?.[0]?.url || '',
            timeString: new Date(record.fields.Date).toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: '2-digit',
              hour12: true,
            }),
          };
        });
        console.log('Processed meals:', meals);
        setDailyMeals(meals);
      } else {
        setDailyMeals([]);
        console.log('No meals found for today.');
      }
    } catch (error) {
      console.error('Error fetching daily meals:', error);
      addNotification('Failed to fetch meals', 'error');
      setDailyMeals([]);
    }
  };

  // Function to fetch daily stats
  const fetchDailyStats = async () => {
    try {
      console.log('Fetching daily stats...');
      const localDate = getLocalDate();
      console.log('Fetching stats for local date:', localDate);

      const statsResponse = await fetchFromAirtable(config.tables.dailyStats, {
        filterByFormula: `AND(LOWER({userEmail}) = LOWER('${userEmail}'), DATETIME_FORMAT(SET_TIMEZONE({Date}, 'Asia/Jerusalem'), 'YYYY-MM-DD') = '${localDate}')`,
      });

      console.log('Stats Response:', statsResponse);

      if (statsResponse.records && statsResponse.records.length > 0) {
        const stats = statsResponse.records[0].fields;
        console.log('Fetched stats:', stats);
        setDailyStats({
          totalCalories: parseFloat(stats.TotalCalories) || 0,
          totalProtein: parseFloat(stats.TotalProtein) || 0,
          totalCarbs: parseFloat(stats.TotalCarbs) || 0,
          totalFat: parseFloat(stats.TotalFat) || 0,
        });
      } else {
        setDailyStats({
          totalCalories: 0,
          totalProtein: 0,
          totalCarbs: 0,
          totalFat: 0,
        });
        console.log('No daily stats found for today.');
      }
    } catch (error) {
      console.error('Error fetching daily stats:', error);
      addNotification('Failed to fetch daily stats', 'error');
      setDailyStats({
        totalCalories: 0,
        totalProtein: 0,
        totalCarbs: 0,
        totalFat: 0,
      });
    }
  };

  // Function to fetch weekly data
  const fetchWeeklyData = async () => {
    try {
      console.log('Fetching weekly data...');
      const today = new Date();
      const dates = [];
      for (let i = 6; i >= 0; i--) {
        const date = new Date(today);
        date.setDate(today.getDate() - i);
        const formattedDate = date.toISOString().split('T')[0];
        dates.push(formattedDate);
      }
      console.log('Weekly dates:', dates);

      const statsResponse = await fetchFromAirtable(config.tables.dailyStats, {
        filterByFormula: `LOWER({userEmail}) = LOWER('${userEmail}')`,
        sort: [{ field: 'Date', direction: 'asc' }],
      });

      console.log('Weekly Stats Response:', statsResponse);

      if (!statsResponse.records) {
        setWeeklyData({
          '7 days': dates,
          calories: Array(7).fill(0),
          protein: Array(7).fill(0),
          carbs: Array(7).fill(0),
          fat: Array(7).fill(0),
        });
        console.log('No weekly stats found.');
        return;
      }

      const dailyTotals = dates.reduce((acc, date) => {
        acc[date] = { calories: 0, protein: 0, carbs: 0, fat: 0 };
        return acc;
      }, {});

      statsResponse.records.forEach((record) => {
        const recordDate = record.fields['Date']?.split('T')[0];
        if (recordDate && dailyTotals[recordDate]) {
          dailyTotals[recordDate].calories = parseFloat(record.fields.TotalCalories) || 0;
          dailyTotals[recordDate].protein = parseFloat(record.fields.TotalProtein) || 0;
          dailyTotals[recordDate].carbs = parseFloat(record.fields.TotalCarbs) || 0;
          dailyTotals[recordDate].fat = parseFloat(record.fields.TotalFat) || 0;
        }
      });

      setWeeklyData({
        '7 days': dates,
        calories: dates.map((date) => Math.round(dailyTotals[date].calories)),
        protein: dates.map((date) => Math.round(dailyTotals[date].protein)),
        carbs: dates.map((date) => Math.round(dailyTotals[date].carbs)),
        fat: dates.map((date) => Math.round(dailyTotals[date].fat)),
      });
      console.log('Weekly Data:', {
        '7 days': dates,
        calories: dates.map((date) => Math.round(dailyTotals[date].calories)),
        protein: dates.map((date) => Math.round(dailyTotals[date].protein)),
        carbs: dates.map((date) => Math.round(dailyTotals[date].carbs)),
        fat: dates.map((date) => Math.round(dailyTotals[date].fat)),
      });
    } catch (error) {
      console.error('Error in fetchWeeklyData:', error);
      addNotification('Failed to fetch weekly data', 'error');
      setWeeklyData({
        '7 days': [],
        calories: [],
        protein: [],
        carbs: [],
        fat: [],
      });
    }
  };
  const fetchUserData = async () => {
    try {
      const response = await fetchFromAirtable(config.tables.users, {
        filterByFormula: `LOWER({email}) = LOWER('${userEmail}')`,
        maxRecords: 1,
      });
  
      if (response.records && response.records.length > 0) {
        const fields = response.records[0].fields;
        setUserData({
          dailyCalorieTarget: parseFloat(fields.dailyCalorieTarget) || 0,
          dailyProteinTarget: parseFloat(fields.dailyProteinTarget) || 0,
          dailyCarbsTarget: parseFloat(fields.dailyCarbsTarget) || 0,
          dailyFatTarget: parseFloat(fields.dailyFatTarget) || 0,
        });
      } else {
        setUserData(null);
        console.warn('No user record found.');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setUserData(null);
    }
  };
  

  // Function to fetch all dashboard data
  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      await Promise.all([
        fetchUserData(),
        fetchDailyMeals(),
        fetchDailyStats(),
        fetchWeeklyData(),
      ]);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      addNotification('Failed to load dashboard data', 'error');
    } finally {
      setLoading(false);
    }
  };

  // useEffect to fetch data on component mount
  useEffect(() => {
    if (!userEmail) {
      navigate('/auth');
      return;
    }

    fetchDashboardData();
  }, [userEmail, navigate]);

  // Render loading state
  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header />
        <div className="flex items-center justify-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500" />
        </div>
      </div>
    );
  }

  // Render error state if any
  if (error) {
    return (
      <div className="min-h-screen bg-gray-50">
        <Header />
        <div className="flex items-center justify-center h-screen">
          <p className="text-red-500">{error}</p>
        </div>
      </div>
    );
  }

  // Add console.log to verify dailyStats before rendering
  console.log('dailyStats in render:', dailyStats);

  // Main Dashboard UI
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Notifications */}
        <div className="absolute top-4 right-4 space-y-2">
          {notifications.map((notification) => (
            <div
              key={notification.id}
              className={`p-4 rounded shadow ${
                notification.type === 'error'
                  ? 'bg-red-100 text-red-700'
                  : notification.type === 'success'
                  ? 'bg-green-100 text-green-700'
                  : 'bg-blue-100 text-blue-700'
              }`}
            >
              {notification.message}
            </div>
          ))}
        </div>

        {/* Daily Stats */}
        {dailyStats && userData && (
  <DailyIntakeCard dailyStats={dailyStats} userGoals={userData} />
)}

        {/* Add Meal Button */}
<div className="mb-6 flex justify-center">
  <button
    onClick={() => navigate('/takephotopage')}
    className="w-full md:w-auto bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow"
  >
    Add Meal
  </button>
</div>

        {/* Today's Meals */}
        <div className="bg-white rounded-lg shadow p-6 mb-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Today's Meals</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {dailyMeals.length === 0 ? (
              <p className="text-gray-500 col-span-full text-center">No meals recorded today</p>
            ) : (
              dailyMeals.map((meal) => (
                <div key={meal.id} className="border rounded-lg p-4">
                  <div className="flex flex-col h-full">
                    <div className="flex justify-between items-start mb-3">
                      <div className="flex-1">
                        <span className="text-sm text-gray-500 block mb-2">
                          {meal.timeString}
                        </span>
                        <h3 className="font-medium text-gray-900">{meal.foodName}</h3>
                      </div>
                      {meal.photoUrl ? (
                        <div className="w-24 h-24 ml-4 flex-shrink-0">
                          <img
                            src={meal.photoUrl}
                            alt={meal.foodName}
                            className="w-full h-full object-cover rounded-lg"
                          />
                        </div>
                      ) : (
                        <div className="w-24 h-24 bg-gray-200 rounded-lg flex items-center justify-center ml-4">
                          <span className="text-gray-400">No photo</span>
                        </div>
                      )}
                    </div>
                    <div className="grid grid-cols-2 gap-2 mt-auto">
                      <div className="text-sm">
                        <span className="text-blue-600">{Math.round(meal.calories)}</span>
                        <span className="text-gray-600"> cal</span>
                      </div>
                      <div className="text-sm">
                        <span className="text-green-600">{Math.round(meal.protein)}</span>
                        <span className="text-gray-600">g protein</span>
                      </div>
                      <div className="text-sm">
                        <span className="text-yellow-600">{Math.round(meal.carbs)}</span>
                        <span className="text-gray-600">g carbs</span>
                      </div>
                      <div className="text-sm">
                        <span className="text-red-600">{Math.round(meal.fat)}</span>
                        <span className="text-gray-600">g fat</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Weekly Progress Chart */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Weekly Progress</h2>
          <div className="h-96">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={weeklyData['7 days'].map((date, index) => ({
                  date,
                  calories: weeklyData.calories[index],
                  protein: weeklyData.protein[index],
                  carbs: weeklyData.carbs[index],
                  fat: weeklyData.fat[index],
                }))}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="calories"
                  stroke="#3b82f6"
                  fill="#3b82f6"
                  opacity={0.3}
                  name="Calories"
                />
                <Area
                  type="monotone"
                  dataKey="protein"
                  stroke="#22c55e"
                  fill="#22c55e"
                  opacity={0.3}
                  name="Protein (g)"
                />
                <Area
                  type="monotone"
                  dataKey="carbs"
                  stroke="#ffc658"
                  fill="#ffc658"
                  opacity={0.3}
                  name="Carbs (g)"
                />
                <Area
                  type="monotone"
                  dataKey="fat"
                  stroke="#ff8042"
                  fill="#ff8042"
                  opacity={0.3}
                  name="Fat (g)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}