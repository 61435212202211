import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function DailyIntakeCard({ dailyStats, userGoals }) {
  const getPercent = (value, goal) => Math.min((value / goal) * 100, 100).toFixed(1);

  const macros = [
    { label: 'Protein', value: dailyStats.totalProtein, goal: userGoals.dailyProteinTarget, color: 'bg-green-500' },
    { label: 'Fats', value: dailyStats.totalFat, goal: userGoals.dailyFatTarget, color: 'bg-red-500' },
    { label: 'Carbs', value: dailyStats.totalCarbs, goal: userGoals.dailyCarbsTarget, color: 'bg-yellow-500' },
  ];

  return (
    <div className="bg-white p-6 rounded-2xl shadow-md flex flex-col items-center w-full max-w-md mx-auto mb-6">
      <div className="relative w-40 h-40 mb-2">
        <div className="absolute inset-0 rounded-full bg-blue-400 blur-2xl opacity-30 animate-pulse"></div>
        <div className="relative z-10">
          <CircularProgressbar
            value={dailyStats.totalCalories}
            maxValue={userGoals.dailyCalorieTarget}
            text={`${Math.round(dailyStats.totalCalories)} Cal`}
            styles={buildStyles({
              textColor: '#111',
              pathColor: '#FF6B35',
              trailColor: '#eee',
            })}
          />
        </div>
      </div>
      <p className="text-gray-600 mb-4 text-sm">of {userGoals.dailyCalorieTarget} Cal</p>

      <div className="grid grid-cols-3 gap-4 w-full text-xs">
        {macros.map(({ label, value, goal, color }) => (
          <div key={label} className="text-center w-full">
            <p className="font-medium">{label}</p>
            <p className="mb-1">{Math.round(value)}/{goal}g</p>
            <div className="w-full h-2 bg-gray-200 rounded-full">
              <div
                className={`h-full ${color} rounded-full`}
                style={{ width: `${getPercent(value, goal)}%` }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}